<template>
  <b-card style="" :class="{ redeemed: isRedeemed }">
    <b-row>
      <b-col md="4">
        <b-img :src="reward.patch" alt="Reward Image" width="300px"></b-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <h5 class="card-title">{{ reward.judul }}</h5>
          <p class="card-text">{{ reward.keterangan }}</p>
          <p class="card-text">
            <strong>{{ reward.nilai }} Poin</strong>
          </p>
          <p class="card-text">
            <strong> Kuota : {{ reward.kouta }}</strong>
          </p>
          <b-button v-if="is_redeem(reward.nilai, rekapPoin)" @click="redeemReward(reward)" :disabled="isRedeemed" variant="gradient-primary">Redeem</b-button>
          <b-button v-else-if="reward.kouta == 0" disabled variant="gradient-primary">Redeem</b-button>
          <b-button v-else disabled variant="gradient-primary">Redeem</b-button>
          <div v-if="isRedeemed" class="mt-2 text-success">Anda berhasil me-redeem {{ reward.name }}!</div>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BImg, BAvatar, BRow, BCol, BCard, BCardBody, BButton, BOverlay } from "bootstrap-vue";
export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
    rekapPoin: {
      type: Object,
      required: true,
    },
    onRedeem: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isRedeemed: false,
    };
  },
  methods: {
    is_redeem(poin, poinSaya) {
      if (poin < poinSaya.dapat_ditarik) {
        return true;
      } else {
        return false;
      }
    },
    redeemReward(reward) {
      if (!this.isRedeemed) {
        this.$swal({
          title: "Anda yakin?",
          text: "Anda akan me-redeem reward ini",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let payload = {
              member_id: this.user.karyawan.id,
              reward_id: reward.id,
            };
            this.$store
              .dispatch("poin/pengajuan", payload)
              .then(() => {
                this.displaySuccess({
                  message: "Reward berhasil di redeem",
                });
                this.onRedeem(this.reward);
                this.isRedeemed = true;
                this.$emit("loadData");
              })
              .catch((e) => {
                this.displayError(e);
                return false;
              });
          }
        });
      }
    },
  },
  components: {
    BImg,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BOverlay,
  },
};
</script>

<style scoped>
.redeemed {
  opacity: 0.7;
}
</style>
